import React from 'react';
import Logo from '@/assets/images/logo.svg';
import SEO from '@/components/modules/SEO';
import { graphql } from 'gatsby';

function CookiePolicy({ data: { strapiMainSeo } }: any) {
  return (
    <>
      <header className="py-12">
        <a href="/" className="w-30 h-30 block mx-auto">
          <Logo />
        </a>
      </header>
      <main className="container">
        <h1 className="text-30 leading-36">Cookie policy</h1>
        <div className="mt-5 text-18">
          <script
            id="CookieDeclaration"
            src="https://consent.cookiebot.com/6ea491ff-0ea8-4277-814a-cf4fa9eb46ef/cd.js"
            type="text/javascript"
            async
          />
        </div>
      </main>
      <SEO {...strapiMainSeo} title="Cookie policy | Orografie" />
    </>
  );
}

export const query = graphql`
  query {
    strapiMainSeo {
      ...StrapiSeo
    }
  }
`;

export default CookiePolicy;
